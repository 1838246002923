import android from "../images/android.jpg";
import web from "../images/web.jpg";
// import digital from "../images/digital.jpg";
import marketing from "../images/marketing.jpg";
import software from "../images/software.jpg";
import fullstack from "../images/fullstack.jpg";

const ServiceData = [
    {
        imgsrc: web,
        title: "Fuels and Additives"
    },
    {
        imgsrc: android,
        title: "Vehicles"
    },
    {
        imgsrc: software,
        title: "Fuel Stations"
    },
    {
        imgsrc: marketing,
        title: "Conversion kits"
    },
    {
        imgsrc: fullstack,
        title: "Performance"
    },
    {
        imgsrc: marketing,
        title: "Economy"
    }
];

export default ServiceData;