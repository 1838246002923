import React from 'react';
import home from "../images/home.svg";
import Common from './Common';

const Home = () => {
    return (
        <>
            <Common 
                name='High-Octane Ethanol, Methanol blends for ICEV´s and FC vehicles.' 
                imgsrc={home} 
                isCompName={true}
                compName="FLEXOFUEL"
                visit='/services' 
                btnname="Coming soon" 
            />
        </>
    )
}

export default Home;